<script>
export default {
  name: "QuestionAnswerComponent",
  props: [
      "questions"
  ],
  data: function () {
    return {
      arrow_down: require("@/assets/img/arrow_down.svg"),
    };
  },
}
</script>

<template>
  <div class="question-block m_100">
    <h2>ВОПРОСЫ И ОТВЕТЫ</h2>
    <div class="answers">
      <div
        class="item"
        v-for="item in questions"
        v-bind:key="item.id"
      >
        <div class="p">{{ item.question }}</div>
        <img :src="arrow_down" alt="">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/css/variables.scss";


.question-block {
  margin-top: 302px;
  @media screen and (max-width: $width-xl) {
    margin-top: 231px;
  }
  @media screen and (max-width: $width-l) {
    margin-top: 214px;
  }
  .answers {
    margin-top: 50px;
    @media screen and (max-width: $width-l) {
      margin-top: 40px;
    }
    .item {
      height: 80px;
      background-color: $white;
      border-radius: 20px;
      padding: 15px 60px 15px 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      @media screen and (max-width: $width-l) {
        margin-bottom: 10px;
      }
    }
  }
}

</style>